import React, { useCallback, useContext } from "react";
import { Button, Card, Row, Col } from "react-bootstrap";
import { AuthStoreContext } from "../../stores";
import { Redirect } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { ReactComponent as MyCourseVilleLogoSVG } from "../../assets/svg/mycourseville_logo.svg";
import { ReactComponent as GoogleGLogo } from "../../assets/svg/g_of_google_logo.svg";

const PortfolioLogin = observer(() => {
  const authStore = useContext(AuthStoreContext);
  const signin = useCallback(
    async (method: string) => {
      authStore.signin(method);
    },
    [authStore]
  );
  if (authStore.isSignin) {
    return <Redirect to="/portfolio" />;
  }
  return (
    <>
      <div className="container">
        <div className="row justify-content-md-center py-3">
          <div className="col-sm-7">
            <Card bg="lite" text="dark" className="mb-2">
              <Card.Header className="text-center">
                คําอธิบายขั้นตอนโดยรวม
              </Card.Header>
              <Card.Body>
                <br />
                <Card.Title> รายละเอียดขั้นตอนการสมัคร </Card.Title>
                {/* <Card.Text>
                  (ลงทะเบียนใช้งานระบบ TCAS64 ตั้งแต่วันที่ 9 ธันวาคม 2564
                  ที่เว็บไซต์ http://student.mytcas.com)
                </Card.Text> */}
                <Row className="py-2">
                  <Col sm={1}>
                    <small>1</small>
                  </Col>
                  <Col sm={11}>
                    ลงทะเบียนใช้งานระบบ TCAS68 ที่เว็บไซต์{" "}
                    <a href="https://student.mytcas.com">https://student.mytcas.com</a>
                  </Col>
                </Row>
                <Row className="py-2">
                  <Col sm={1}>
                    <small>2</small>
                  </Col>
                  <Col sm={11}>
                    จัดทำแฟ้มสะสมผลงานอิเล็กทรอนิกส์ (E-portfolio) ของตนเองผ่านระบบออนไลน์ที่เว็บไซต์{" "}
                    <a href="https://portfolio.eng.chula.ac.th">https://portfolio.eng.chula.ac.th</a>
                  </Col>
                </Row>
                <Row className="py-2">
                  <Col sm={1}>
                    <small>3</small>
                  </Col>
                  <Col sm={11}>
                    เมื่อผู้สมัครจัดทำแฟ้มสะสมผลงานอิเล็กทรอนิกส์ (E-portfolio) เสร็จสิ้นแล้ว{" "}
                    ผู้สมัครจะต้องดาวน์โหลด (Download) แฟ้มสะสมผลงานอิเล็กทรอนิกส์ (E-portfolio) จากระบบออนไลน์{" "}
                    โดยแฟ้มสะสมผลงานอิเล็กทรอนิกส์ (E-portfolio) ฉบับที่ถูกต้องจะต้องไม่มีลายน้ำปรากฏในเอกสาร เพื่อนำไปใช้อัปโหลดในขั้นตอนที่ 4
                  </Col>
                </Row>
                <Row className="py-2">
                  <Col sm={1}>
                    <small>4</small>
                  </Col>
                  <Col sm={11}>
                    สมัครและอัปโหลดหลักฐานประกอบการสมัครตามประกาศ จุฬาฯ{" "}
                    เรื่องการรับสมัครคัดเลือกนักเรียนที่มีความรู้ทางด้านคณิตศาสตร์และวิทยาศาสตร์เข้าศึกษาในคณะวิศวกรรมศาสตร์{" "}
                    ปีการศึกษา 2568 TCAS 68 รอบที่ 1 แฟ้มสะสมผลงาน (Portfolio) ข้อ 5.2 ผ่านระบบการคัดเลือกของศูนย์ทดสอบทางวิชาการแห่งจุฬาฯ{" "}
                    ที่เว็บไซต์ https://tcas.atc.chula.ac.th ในระหว่างวันที่ 5-18 พฤศจิกายน 2567{" "}
                    (โดยระบบรับสมัครและอัปโหลดหลักฐานประกอบการสมัครจะปิดในวันที่ 18 พฤศจิกายน 2567 เวลา 16.00 น.)
                  </Col>
                </Row>
                <Row className="py-2">
                  <Col sm={1}>
                    <small>5</small>
                  </Col>
                  <Col sm={11}>
                    ชำระค่าธรรมเนียมการสมัคร ทั้งนี้ สถานะการสมัครจะเสร็จสมบูรณ์ต่อเมื่อผู้สมัครได้กรอกข้อมูลผ่านระบบการรับสมัคร{" "}
                    แนบไฟล์รูปถ่ายและบัตรประจำตัวประชาชนที่กำหนดตามข้อ 5.1.2 ชำระเงินค่าธรรมเนียมการสมัครและอัปโหลดไฟล์หลักฐานการสมัครตามข้อ 5.2 ภายในระยะเวลาที่กำหนด
                  </Col>
                </Row>
                {/* <br />
                <Card.Title>
                  วีดิโอแนะนำการทำแฟ้มสะสมผลงานอิเล็กทรอนิกส์ (E-Portfolio)
                </Card.Title>
                <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/pfxAUjHCrLg?si=R5OODZJI4kXiIOev" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                <br /> */}
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-5">
            <Card bg="lite" text="dark" className="mb-2">
              <Card.Header className="text-center">
                ขั้นตอนการเข้าสู่ระบบ
              </Card.Header>
              <Card.Body>
                <Card.Title>
                  {" "}
                  ผู้สมัครสามารถเข้าสู่ระบบได้ด้วยบัญชี myCourseVille หรือ
                  Google{" "}
                </Card.Title>

                <br />
                <Card.Text>
                  <span className="font-weight-bold">
                    - การเข้าสู่ระบบด้วยบัญชี myCourseVille
                  </span>
                </Card.Text>
                <Row className="py-2">
                  <Col sm={1}>
                    <small>1</small>
                  </Col>
                  <Col sm={11}>
                    คลิกปุ่ม "เข้าสู่ระบบด้วยบัญชี myCourseVille"
                  </Col>
                </Row>
                <Row className="py-2">
                  <Col sm={1}>
                    <small>2</small>
                  </Col>
                  <Col sm={11}>
                    กรอกชื่อบัญชีและรหัสผ่าน หากยังไม่มีบัญชี myCourseVille
                    สามารถสมัครได้ด้วยการกด "ลงทะเบียนบัญชีใหม่"
                    ในกรณีที่ผู้สมัครลืมรหัสผ่าน สามารถกด "ลืมรหัสผ่าน?"
                    เพื่อเปลี่ยนรหัสผ่านได้
                  </Col>
                </Row>
                <Row className="py-2">
                  <Col sm={1}>
                    <small>3</small>
                  </Col>
                  <Col sm={11}>
                    หากเป็นการเข้าใช้งานครั้งแรก ระบบจะขอเข้าถึงข้อมูลที่จำเป็น
                    ผู้สมัครต้องอนุญาตเพื่อเข้าใช้งานระบบ
                  </Col>
                </Row>

                <br />
                <Card.Text>
                  <span className="font-weight-bold">
                    - การเข้าสู่ระบบด้วยบัญชี Google
                  </span>
                </Card.Text>
                <Row className="py-2">
                  <Col sm={1}>
                    <small>1</small>
                  </Col>
                  <Col sm={11}>คลิกปุ่ม "เข้าสู่ระบบด้วยบัญชี Google"</Col>
                </Row>
                <Row className="py-2">
                  <Col sm={1}>
                    <small>2</small>
                  </Col>
                  <Col sm={11}>
                    กรอกชื่อบัญชีอีเมลของ Google
                    หากยังไม่มีบัญชีอีเมลสามารถสมัครได้ด้วยการกด "สร้างบัญชี"
                    ในกรณีที่ผู้สมัครลืมอีเมล สามารถกด "หากลืมอีเมล"
                    เพื่อกู้คืนอีเมลได้
                  </Col>
                </Row>
                <Row className="py-2">
                  <Col sm={1}>
                    <small>3</small>
                  </Col>
                  <Col sm={11}>
                    กรอกรหัสผ่านของบัญชีอีเมล Google
                    ในกรณีที่ผู้สมัครลืมรหัสผ่าน สามารถกด "หากลืมรหัสผ่าน"
                    เพื่อเปลี่ยนรหัสผ่านได้
                  </Col>
                </Row>
                <Row className="py-2">
                  <Col sm={1}>
                    <small>4</small>
                  </Col>
                  <Col sm={11}>
                    หากเป็นการเข้าใช้งานครั้งแรก ระบบจะขอเข้าถึงข้อมูลที่จำเป็น
                    ผู้สมัครต้องอนุญาตเพื่อเข้าใช้งานระบบ
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <div className="login-botton py-3">
              <Button
                variant="light"
                size="lg"
                block
                onClick={() => signin("mcv")}
              >
                <span className="align-middle">
                  เข้าสู่ระบบด้วยบัญชี
                </span>
                <span className="mx-2">
                    <MyCourseVilleLogoSVG style={{ height: "2rem", width: "auto" }} />
                </span>
              </Button>
              <Button
                variant="light"
                size="lg"
                block
                onClick={() => signin("google")}
              >
                <span className="align-middle">
                  เข้าสู่ระบบด้วยบัญชี
                </span>
                <span className="mx-2">
                    <GoogleGLogo style={{ height: "2rem", width: "auto" }} />
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default PortfolioLogin;
