import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Card,
  ListGroup,
  Tab,
  Row,
  Col,
  Form,
  Modal,
} from "react-bootstrap";
import Portfolio from "./Portfolio";
import { Part } from "../../types";
import { useMemo } from "react";
import axios from "axios";
import { FaCheck, FaTimes } from "react-icons/fa";
import { observer } from "mobx-react-lite";
import { AuthStoreContext } from "../../stores";
import "./style.scss";

const MyVerticallyCenteredModal = (props: any) => {
  const [check, setcheck] = useState(false);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
    >
      <Form onSubmit={(e) => e.preventDefault()}>
        <Modal.Header closeButton className="text-center">
          <Modal.Title id="contained-modal-title-vcenter">
            {/* แสดงตัวอย่างแฟ้มสะสมผลงานอิเล็กทรอนิกส์ (E-Portfolio) */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <Row>
            <Col sm={1}></Col>
            <Col sm={10}>
              <p>
                ข้าพเจ้าขอรับรองว่าข้อมูลและหลักฐานการสมัครข้างต้นถูกต้องและเป็นความจริงทุกประการ
                ทั้งนี้ หากตรวจสอบพบว่า
                ข้าพเจ้าให้ข้อมูลและหลักฐานไม่ถูกต้องตามความเป็นจริงหรือเป็นผู้มีคุณสมบัติไม่ตรง
                ตามประกาศจุฬาลงกรณ์มหาวิทยาลัยเรื่องการรับสมัครคัดเลือกนักเรียน
                ที่มีความรู้ทางด้านคณิตศาสตร์และวิทยาศาสตร์เข้าศึกษาในคณะวิศวกรรมศาสตร์
                ประจำปีการศึกษา 2568 TCAS68 รอบที่ 1 แฟ้มสะสมผลงาน (Portfolio)
                ให้ถือว่าข้าพเจ้าเป็นผู้ไม่มีสิทธิเข้ารับการคัดเลือกฯ ในครั้งนี้
              </p>
            </Col>
          </Row>
          <Form.Group className="pt-3">
            <Form.Check
              required
              label="ยืนยันว่าข้อมูลและหลักฐานการสมัครข้างต้นถูกต้องและเป็นความจริงทุกประการ"
              feedback="กรุณากดยืนยันก่อนดำเนินการต่อ"
              checked={check}
              onChange={() => setcheck(!check)}
            />
          </Form.Group>
          <Row>
            <p>กดปุ่มถัดไปเพื่อแสดงตัวอย่างแฟ้มสะสมผลงานสำหรับการตรวจสอบความถูกต้องของข้อมูล</p>
          </Row>
        </Modal.Body>
        <Modal.Footer className="text-center">
          <Button
            className="mr-auto"
            variant="secondary"
            onClick={props.onHide}
            type="button"
          >
            ยกเลิก
          </Button>
          <Button
            variant="primary"
            type="submit"
            className={!check ? `disabled not-allowed` : ``}
          >
            ถัดไป
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const PortfolioManager = observer(() => {
  const authStore = useContext(AuthStoreContext);
  const [parts, setParts] = useState<Part[]>([]);
  const [current, setCurrent] = useState<number>(1);
  const [modalShow, setModalShow] = useState(false);
  const [history] = useState(useHistory());

  useEffect(() => {
    axios.get(`/portfolio/me`).then((response) => {
      setParts(response.data);
    }).catch((err) => {
      authStore.signout()
        .then(() => window.location.href = `/`)
    });
    // setParts([
    //   {
    //     part: 1,
    //     completed: false,
    //     secs: [
    //       {
    //         sec: 1,
    //         files: [
    //           {
    //             id: 1,
    //             url: "",
    //             des: "3.87,4.00,3.5",
    //             check: false,
    //           },
    //         ]
    //       },
    //       {
    //         sec: 2,
    //         files: [
    //           {
    //             id: 1,
    //             url: "",
    //             des: "",
    //             check: false,
    //           },
    //         ]
    //       },
    //       {
    //         sec: 3,
    //         files: [
    //           {
    //             id: 1,
    //             url: "",
    //             des: "",
    //             check: false,
    //           },
    //         ]
    //       },
    //     ]
    //   },
    // ]);
  }, []);

  useEffect(() => {
    if (authStore.isfinalized) {
      history.push("/portfolio/finalize");
    }
  }, [authStore.isfinalized])

  const selectedPart = useMemo(() => {
    return parts.find((part) => part.part === current);
  }, [parts, current]);

  const isPortComplete = () => {
    let chk = parts.find((part) => part.completed === false);
    return chk === undefined ? true : false;
  };

  const onSubmit = () => {
    history.push("/portfolio/example");
  };

  return (
    <>
      <div className="container py-3">
        <Tab.Container id="list-group-tabs-example" defaultActiveKey="#1">
          <Row>
            <Col sm={4}>
              <Card bg="lite" text="dark" className="mb-2">
                <Card.Header className="text-center">
                  หัวข้อแฟ้มสะสมผลงาน
                </Card.Header>
                <ListGroup>
                  {parts.map((part) => {
                    return (
                      <ListGroup.Item
                        className={part.part === current ? "active" : ""}
                        key={`part-${part.part}`}
                        action
                        onClick={() => {
                          setCurrent(part.part);
                        }}
                      >
                        <Row>
                          <Col sm={10}>
                            {part.part === 1 && "1) ความสามารถทางวิชาการ"}
                            {part.part === 2 &&
                              "2) แรงผลักดันหรือแรงจูงใจในการเรียนคณะวิศวฯ และ 3) ความสามารถในการสื่อสาร"}
                            {part.part === 4 && "4) ความริเริ่มสร้างสรรค์และความสามารถในการแก้ปัญหา"}
                            {part.part === 5 &&
                              "5) การศึกษาเรียนรู้ด้วยตัวเองและต่อเนื่อง"}
                            {/* {part.part === 6 &&
                              "ความริเริ่มสร้างสรรค์และการทำงานเป็นกลุ่ม"} */}
                          </Col>
                          <Col sm={2}>
                            {part.completed ? (
                              <FaCheck size={25} color="green" />
                            ) : (
                              <FaTimes size={25} color="red" />
                            )}
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </Card>
              <div className="submit-botton py-3 text-center">
                <div className="pb-3">
                  {authStore.isComplete &&
                  isPortComplete() &&
                  !authStore.isfinalized ? (
                    <Button
                      variant="primary"
                      size="lg"
                      block
                      onClick={() => setModalShow(true)}
                    >
                      แสดงตัวอย่างแฟ้มสะสมผลงานอิเล็กทรอนิกส์ (E-Portfolio)
                    </Button>
                  ) : (
                    <Button
                      className="not-allowed"
                      variant="secondary"
                      size="lg"
                      block
                      disabled
                    >
                      แสดงตัวอย่างแฟ้มสะสมผลงานอิเล็กทรอนิกส์ (E-Portfolio)
                    </Button>
                  )}
                </div>

                {!authStore.isComplete && (
                  <div>
                    <div className="pb-3">
                      <Link to="/register">
                        <Button variant="danger" size="lg" block>
                          กรอกเลขที่ใบสมัคร
                        </Button>
                      </Link>
                    </div>
                    <p className="text-danger ">
                      *คุณยังไม่ได้กรอกเลขที่ใบสมัคร*
                    </p>
                  </div>
                )}
                {!isPortComplete() && (
                  <p className="text-danger ">
                    *เพิ่มแฟ้มสะสมผลงานให้ครบเพื่อเนินการต่อ*
                  </p>
                )}
              </div>
            </Col>
            <Col sm={8}>
              <Tab.Content>
                {selectedPart && (
                  <Portfolio part={selectedPart} setParts={setParts} />
                )}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSubmit={onSubmit}
      />
    </>
  );
});

export default PortfolioManager;
